import axios from "axios";
import React, { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useLocation, useParams } from "react-router-dom";
import { Base_Url } from "../../../misc";
import { useForm } from "react-hook-form";

const RiderDetails = () => {
  const location = useLocation();
  const [item, setItem] = useState(location.state);

  const { register, handleSubmit, reset, getValues, setValue } = useForm();
  const params = useParams();

  // useEffect(() => {
  //   setItem(location.state);
  // }, []);
  console.log(item);
  const getRiderDetails = async () => {
    try {
      const response = await axios.get(
        `${Base_Url}/get-single-rider/${params.id}`
      );
      if (response.data.status === "Success") {
        setItem(response.data.data[0]);
        reset({ ...response.data.data[0] });
      }
    } catch (e) {}
  };
  const {
    riderName,
    riderMobileNo,
    riderApproval,
    cityID,
    regionID,
    countryID,
    vehicleType,
    vehicleDetails,
    NIDNumber,
    NidExpireDate,
    riderNIDImg,
    License,
    riderLicenseImg,
  } = item;

  return (
    <div>
      <div>
        <div className='card'>
          <div className='card-header border-0 pb-0'>
            <h3>Rider Information</h3>
          </div>
          <div className='card-body'>
            <div className='row align-items-center'>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Rider Name</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankAccName'
                  value={riderName}
                />
              </div>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Rider Mobile No.</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankAccNo'
                  value={riderMobileNo}
                />
              </div>
              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Rider Approval</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankAccNo'
                  value={riderApproval ? "Approved" : "Pending"}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Country</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={countryID}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Region</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankRoutingNo'
                  value={regionID}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>City</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={cityID}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Vehicle Type</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={vehicleType}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Vehicle Reg No.</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}


                  name="bankBranchName"
                  value={vehicleDetails?.[0]?.vehicleRegNo || ''}

                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Vehicle Tax Token</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}

                  name="bankBranchName"
                  value={vehicleDetails?.[0]?.vehicleTaxToken || ''}

                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Vehicle Tax Token Expiry Date</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}

                  name="bankBranchName"
                  // value={vehicleDetails[0].vehicleTaxTokenExpiry}
                  value={vehicleDetails?.[0]?.vehicleTaxTokenExpiry || ''}

                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Route Permit Expiry Date</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}

                  name="bankBranchName"
                  // value={vehicleDetails[0].routePermitExpiryDate}
                  value={vehicleDetails?.[0]?.routePermitExpiryDate || ''}

                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Fitness Certifcate Expiry Date</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}

                  name="bankBranchName"
                  // value={vehicleDetails[0].fitnessCertificateExpiryDate}
                  value={vehicleDetails?.[0]?.fitnessCertificateExpiryDate || ''}

                />
              </div>

              {/* <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Vehicle Registration Image</h6>
              </div>
              <div className='d-flex'>
                <PhotoProvider>
                  <div className='d-flex m-3 rounded'>
                    <div className='m-2'>
                      <PhotoView
                        src={


                          vehicleDetails?.[0]?.vehicleRegImg?.frontImg?.[0]?.large?.imageUrl || ''

                        }
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={

                            vehicleDetails?.[0]?.vehicleRegImg?.frontImg?.[0]?.small?.imageUrl || ''

                          }
                          alt=''
                        />
                      </PhotoView>
                    </div>
                    <div className='m-2'>
                      <PhotoView
                        src={

                          vehicleDetails?.[0]?.vehicleRegImg?.backImg?.[0]?.large?.imageUrl || ''

                        }
                        className='p-2'
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={

                            vehicleDetails?.[0]?.vehicleRegImg?.backImg?.[0]?.large?.imageUrl || ''

                          }
                          alt=''
                        />
                      </PhotoView>
                    </div>
                  </div>
                </PhotoProvider>
                <div className='m-3 rounded'></div>
              </div> */}

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>License Type</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={License && License[0]?.licenseType}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>License No</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={License && License[0]?.licenseNo}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>License Expiry Date</h6>
              </div>
              <div className='col-xl-8 mb-3'>
                <input
                  disabled
                  type='text'
                  className='form-control input-default '
                  // placeholder={}
                  name='bankBranchName'
                  value={License && License[0]?.licenseExpiryDate}
                />
              </div>

              <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Rider NID Image</h6>
              </div>
              <div className='d-flex'>
                {/* <div className="m-3 rounded">
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    // src={riderNIDimg[0]?.orginalImageUrl}
                    alt=""
                  />
                </div> */}
                <PhotoProvider>
                  <div className='m-3 rounded'>
                    <PhotoView
                    // src={
                    //   riderNIDImg[0]?.orginalImageUrl
                    // }
                    >
                      <img
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                        // src={
                        //   riderNIDImg[0]?.orginalImageUrl
                        // }
                        alt=''
                      />
                    </PhotoView>
                  </div>
                </PhotoProvider>
              </div>

              {/* <div className='col-xl-4 mb-3'>
                <h6 className='mb-0'>Rider License Image</h6>
              </div>
              <div className='d-flex'>
                <PhotoProvider>
                  <div className=' d-flex m-3 rounded'>
                    <div className='m-2'>
                      <PhotoView
                        src={
                          riderLicenseImg &&
                          riderLicenseImg[0]?.frontImg[0]?.large?.imageUrl
                        }
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={
                            riderLicenseImg &&
                            riderLicenseImg[0]?.frontImg[0]?.small?.imageUrl
                          }
                          alt=''
                        />
                      </PhotoView>
                    </div>
                    <div className='m-2'>
                      <PhotoView
                        src={
                          riderLicenseImg &&
                          riderLicenseImg[0]?.backImg[0]?.large?.imageUrl
                        }
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={
                            riderLicenseImg &&
                            riderLicenseImg[0]?.frontImg[0]?.small?.imageUrl
                          }
                          alt=''
                        />
                      </PhotoView>
                    </div>
                  </div>
                </PhotoProvider>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RiderDetails;
