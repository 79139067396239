import axios from "axios";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Dropdown, Button } from "react-bootstrap";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";
import makeAnimated from "react-select/animated";
const StyleFive = () => {
  const animatedComponents = makeAnimated();
  const [category, setCategory] = useState([]);
  const [options, setOptions] = useState();
  const foodTypeOptions = [
    { value: "INSTANT", label: "INSTANT" },
    { value: "PREORDER", label: "PREORDER" },
    { value: "CATERING", label: "CATERING" },
    { value: "ALL", label: "ALL" },
  ];
  const [firstSelectedFoodTypeOptions, setFirstSelectedFoodTypeOptions] =
    useState();
  const [secondSelectedFoodTypeOptions, setSecondSelectedFoodTypeOptions] =
    useState();
  const [thirdSelectedFoodTypeOptions, setThirdSelectedFoodTypeOptions] =
    useState();
  const [fourthSelectedFoodTypeOptions, setFourthSelectedFoodTypeOptions] =
    useState();
  const getCat = () => {
    axios

      .get(`${Base_Url}/get-category`)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const FilterdData = async (id) => {
    const Data = await category.filter((i) => i._id === id);
    return Data;
  };
  useEffect(() => {
    getCat();
  }, []);
  const handleStyleFive = async (e) => {
    e.preventDefault();
    const form = e.target;
    const title1 = form.sectionTitle1.value;
    const title2 = form.sectionTitle2.value;
    const title3 = form.sectionTitle3.value;
    const title4 = form.sectionTitle4.value;
    const orderby = form.Orderby.value;
    const displayLimit = form.Displaylimit.value;
    const category1 = form.category1.value;
    const category2 = form.category2.value;
    const category3 = form.category3.value;
    const category4 = form.category4.value;
    const category1data = await FilterdData(category1);
    const category2data = await FilterdData(category2);
    const category3data = await FilterdData(category3);
    const category4data = await FilterdData(category4);
    console.log(category1data);
    const data = {
      sectionTitle1: title1,
      sectionTitle2: title2,
      sectionTitle3: title3,
      sectionTitle4: title4,
      sectionStyle: "Style5",

      orderBy: orderby,
      // status: true,

      displayLimit: displayLimit,
      sectionCategories1: [
        { label: category1data[0].categoryName, value: category1 },
      ],
      sectionCategories2: [
        { label: category2data[0].categoryName, value: category2 },
      ],
      sectionCategories3: [
        { label: category3data[0].categoryName, value: category3 },
      ],
      sectionCategories4: [
        { label: category4data[0].categoryName, value: category4 },
      ],
    };

    if (firstSelectedFoodTypeOptions) {
      data["sectionFoodTypeCategories1"] = firstSelectedFoodTypeOptions;
    }
    if (secondSelectedFoodTypeOptions) {
      data["sectionFoodTypeCategories2"] = secondSelectedFoodTypeOptions;
    }
    if (thirdSelectedFoodTypeOptions) {
      data["sectionFoodTypeCategories3"] = thirdSelectedFoodTypeOptions;
    }
    if (fourthSelectedFoodTypeOptions) {
      data["sectionFoodTypeCategories4"] = fourthSelectedFoodTypeOptions;
    }

    await axios
      .post(`${Base_Url}/create-home-page-sections`, data)
      .then((res) => {
        console.log("sucess", res.data.status);
        if (res.data.status === "Success") {
          Swal.fire({
            icon: "success",
            title: "Style has Created",
            showConfirmButton: false,
            timer: 1500,
          });
          form.reset();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <form onSubmit={handleStyleFive}>
        <h4>Design Section Five (Four by Two)</h4>
        <Row>
          <Col>
            <Card>
              <Card.Header className='d-block'>
                <Card.Title>Section Layout One (Four Food)</Card.Title>
                <Card.Text className='m-0 subtitle'>
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username1'
                          >
                            Section Title one
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              className='form-control'
                              id='val-username1'
                              name='sectionTitle1'
                              placeholder='Enter a section Title..'
                            />
                          </div>
                        </div>

                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username1'
                          >
                            Section Category
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <select name='category1' className='form-control'>
                              <option disabled>select category</option>
                              {category &&
                                category.map((item) => (
                                  <option value={item._id}>
                                    {item.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Food Type
                            <span className='text-danger'>*</span>
                          </label>

                          <div className='col-lg-6'>
                            <Select
                              value={firstSelectedFoodTypeOptions}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) =>
                                setFirstSelectedFoodTypeOptions(item)
                              }
                              classNamePrefix='select'
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={foodTypeOptions}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header className='d-block'>
                <Card.Title>Section Layout Two(Four Food)</Card.Title>
                <Card.Text className='m-0 subtitle'>
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username2'
                          >
                            Section Title
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              className='form-control'
                              id='val-username2'
                              name='sectionTitle2'
                              placeholder='Enter a Section Title..'
                            />
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username2'
                          >
                            Section Category
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <select name='category2' className='form-control'>
                              <option disabled>select category</option>
                              {category &&
                                category.map((item) => (
                                  <option value={item._id}>
                                    {item.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Food Type
                            <span className='text-danger'>*</span>
                          </label>

                          <div className='col-lg-6'>
                            <Select
                              value={secondSelectedFoodTypeOptions}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) =>
                                setSecondSelectedFoodTypeOptions(item)
                              }
                              classNamePrefix='select'
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={foodTypeOptions}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <>
          <h3> Design Single Item </h3>
        </>
        <Row>
          <Col>
            <Card>
              <Card.Header className='d-block'>
                <Card.Title>
                  Section Layout Three (Single Food Or Category Carousel)
                </Card.Title>
                <Card.Text className='m-0 subtitle'>
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username3'
                          >
                            Section Title
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              className='form-control'
                              id='val-username3'
                              name='sectionTitle3'
                              placeholder='Enter a section Title..'
                            />
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Section Category
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <select name='category3' className='form-control'>
                              <option disabled>select category</option>
                              {category &&
                                category.map((item) => (
                                  <option value={item._id}>
                                    {item.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Food Type
                            <span className='text-danger'>*</span>
                          </label>

                          <div className='col-lg-6'>
                            <Select
                              value={thirdSelectedFoodTypeOptions}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) =>
                                setThirdSelectedFoodTypeOptions(item)
                              }
                              classNamePrefix='select'
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={foodTypeOptions}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header className='d-block'>
                <Card.Title>
                  Section Layout Four(Single Food Or Category)
                </Card.Title>
                <Card.Text className='m-0 subtitle'>
                  Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Row>
                <Col>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username4'
                          >
                            Section Title
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <input
                              type='text'
                              className='form-control'
                              id='val-username4'
                              name='sectionTitle4'
                              placeholder='Enter a Section Title..'
                            />
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username4'
                          >
                            Section Category
                            <span className='text-danger'>*</span>
                          </label>
                          <div className='col-lg-6'>
                            <select name='category4' className='form-control'>
                              <option>select category</option>
                              {category &&
                                category.map((item) => (
                                  <option value={item._id}>
                                    {item.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className='form-group mb-3 row'>
                          <label
                            className='col-lg-4 col-form-label'
                            htmlFor='val-username'
                          >
                            Food Type
                            <span className='text-danger'>*</span>
                          </label>

                          <div className='col-lg-6'>
                            <Select
                              value={fourthSelectedFoodTypeOptions}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              // defaultValue={selectedOptions}
                              onChange={(item) =>
                                setFourthSelectedFoodTypeOptions(item)
                              }
                              classNamePrefix='select'
                              // defaultValue={[Options[4], Options[5]]}
                              isMulti
                              options={foodTypeOptions}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <div className='form-group mb-3 row'>
            <label className='col-lg-4 col-form-label' htmlFor='val-username5'>
              Section Orderby
              <span className='text-danger'>*</span>
            </label>
            <div className='col-lg-6'>
              <input
                type='text'
                className='form-control'
                id='val-username5'
                name='Orderby'
                placeholder='Enter a section Orderby..'
              />
            </div>
          </div>

          <div className='form-group mb-3 row'>
            <label className='col-lg-4 col-form-label' htmlFor='val-username6'>
              Section Displaylimit
              <span className='text-danger'>*</span>
            </label>
            <div className='col-lg-6'>
              <input
                type='text'
                className='form-control'
                id='val-username6'
                name='Displaylimit'
                placeholder='Enter a section Display Limit..'
              />
            </div>
          </div>
        </Row>
        <Button type='submit'>Save</Button>
      </form>
    </div>
  );
};

export default StyleFive;
