import React, { useState, useEffect } from "react";
import { AiFillInteraction } from "react-icons/ai";
import {
  Row,
  Card,
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";
import { Rider } from "./dummy_rider";
import MyPagination from "../Pagination/MyPagination";
import { AiFillEye } from "react-icons/ai";

const ApprovedRider = () => {
  const [Rider, setRider] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);
  const [totalItem, setTotalItem] = useState(0);
  const [filter1, setFilter1] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sortItemsByDate = () => {
    console.log("Inside function");
    const sortedItems = [...Rider].sort((a, b) => {
      let aDate = new Date(a.createdDate);
      let bDate = new Date(b.createdDate);
      console.log(aDate);
      console.log(bDate);
      return bDate - aDate;
    });
    console.log(sortedItems);
    setFilteredData(sortedItems);
  };
  const sortItemsByDate2 = () => {
    console.log("Inside function");
    const sortedItems = [...Rider].sort((a, b) => {
      let aDate = new Date(a.createdDate);
      let bDate = new Date(b.createdDate);
      console.log(aDate);
      console.log(bDate);
      return aDate - bDate;
    });
    console.log(sortedItems);
    setFilteredData(sortedItems);
  };

  // Add an effect to update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    let filtered = Rider.filter(
      (item) =>
        item?.riderName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.riderMobileNo?.includes(searchTerm) ||
        item?.vehicleType?.includes(searchTerm)
    );

    // Check if there are no matching results and searchTerm is not empty
    if (searchTerm.trim() !== "" && filtered.length === 0) {
      filtered = [];
    }

    setFilteredData(filtered);
  }, [searchTerm, Rider, filter1]);

  const handlesort = (value) => {
    if (value == "all") {
      // setfilter1("All");
      setFilteredData([...Rider]);
      // setfilter1("All");
    } else if (value === "active") {
      // setfilter1("Active");
      const sortedItems = Rider.filter((item) => item?.status === true);

      setFilteredData(sortedItems);
      // setfilter1("Active");
    } else if (value === "inactive") {
      // setfilter1("InActive");
      const sortedItems = Rider.filter((item) => item?.status === false);

      setFilteredData(sortedItems);
      // setfilter1("InActive");
    }
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-rider`)

      .then((res) => {
        let fetchedData = res.data.data;
        fetchedData.filter((item) => item?.riderApproval === "Approved");
        setRider(fetchedData);
        console.log(Rider);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const block = async (_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      input: "textarea",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactivate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result);

        // console.log(blockNote);
        handleBlock(_id, result.value);
      }
    });
  };

  const unBlock = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Unblock it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnblock(_id);
      }
    });
  };

  const handleBlock = (id, message) => {
    axios
      .post(`${Base_Url}/update-rider/${id}`, {
        status: false,
        blockNote: message,
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = Rider.filter((r) => r._id !== id);
          setRider(updateData);
          Swal.fire({
            icon: "success",
            title: "Rider Blocked Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnblock = (id) => {
    axios
      .post(`${Base_Url}/update-rider/${id}`, {
        status: true,
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = Rider.filter((r) => r._id !== id);
          setRider(updateData);
          Swal.fire({
            icon: "success",
            title: "Rider Approved Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const comment = async (_id) => {
    await Swal.fire({
      title: "Add a comment",
      text: "You won't be able to revert this!",
      input: "textarea",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result);

        // console.log(blockNote);
        handleComment(_id, result.value);
      }
    });
  };

  const handleComment = (id, message) => {
    axios
      .post(`${Base_Url}/update-rider/${id}`, {
        comment: message,
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = Rider.filter((r) => r._id !== id);
          setRider(updateData);
          Swal.fire({
            icon: "success",
            title: "Comment added Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-rider/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const UpdateData = Rider.filter((r) => r._id !== id);
          setRider(UpdateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Somethig Want Wrong Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };
  const handleApproval = (id) => {
    Swal.fire({
      title: "Are you give  Approval for food delivery?",
      // text: "You won't be able to revert this!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_Url}/update-rider/${id}`, { riderApproval: "Approved" })
          .then((res) => {
            console.log(res.data.data.modifiedCount);
            if (res.data.data.modifiedCount > 0) {
              const UpdateData = Rider.filter((r) => r._id !== id);
              filteredData(UpdateData);
              Swal.fire({
                icon: "success",
                title: "Rider Approved SuccessFully!!",
                showConfirmButton: false,
                timer: 1000,
              });
            } else
              Swal.fire({
                icon: "warning",
                title: "Somethig Want Wrong Try Again",
                showConfirmButton: false,
                timer: 150,
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const handleApproved = (id) => {
    axios
      .post(`${Base_Url}/update-rider/${id}`, { riderApproval: "Approved" })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const UpdateData = Rider.filter((r) => r._id !== id);
          filteredData(UpdateData);
          Swal.fire({
            icon: "success",
            title: "Rider Approved SuccessFully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Somethig Want Wrong Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (filteredData.length > 0 ? filteredData : Rider).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  console.log(filteredData, currentItems);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData?.length : Rider?.length) / PAGE_SIZE
  );

  return (
    <>
      {windowWidth <= 750 && (
        <div
          className="mb-4 align-items-center border p-4 rounded bg-black "
          style={{ backgroundColor: "white" }}
        >
          <div
            className="mt-2 mb-2 pl-2 input-group search-area2 w-100"
            // style={{ width: "100%" }}
          >
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Filter Name, Phone, Kitchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              Sort By Date{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => sortItemsByDate()}
                eventKey="Activity"
              >
                Latest Date
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => sortItemsByDate2()}
                eventKey="Activity"
              >
                Oldest Date
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Filter by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              Sort By Status
              {/* {filter1} */}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => sortAll()} eventKey="Activity">
                All
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => sortInactive()}
                eventKey="Activity"
              >
                Inactive
              </Dropdown.Item>

              <Dropdown.Item onClick={(e) => sortActive()} eventKey="Activity">
                Active
              </Dropdown.Item>
            </Dropdown.Menu> */}

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={(e) => handlesort("all")}
                // eventKey="Activity"
              >
                All
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => handlesort("inactive")}
                // eventKey="Activity"
              >
                Inactive
              </Dropdown.Item>

              <Dropdown.Item
                onClick={(e) => handlesort("active")}
                // eventKey="Activity"
              >
                Active
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/*  Sort by */}
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "1px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {/* {apiPagination}{" "} */}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                // onClick={() => setApiPagination("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>

              <Dropdown.Item
                // onClick={() => setApiPagination("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>

              <Dropdown.Item
                // onClick={() => setApiPagination("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>

              <Dropdown.Item
                // onClick={() => setApiPagination("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              <i
                className="fa-solid fa-location-dot mx-2"
                style={{ color: "orange" }}
              />{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                // onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                Thana
              </Dropdown.Item>

              <Dropdown.Item
                //   onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                Region
              </Dropdown.Item>

              <Dropdown.Item
                // onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                City
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      )}
      {windowWidth >= 751 && (
        <div
          className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="input-group search-area2 style-1 w-[75%] border d-flex"
            style={{ marginRight: "15px" }}
          >
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>

            <input
              type="text"
              className="form-control p-2 "
              placeholder="Filter by name phone vehicle type"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="input-group ml-2 pl-2 style-1 w-50">
            {/* Search filter */}
            <Dropdown
              className="notification-select"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                Sort By Date{" "}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => sortItemsByDate()}
                  eventKey="Activity"
                >
                  Latest Date
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => sortItemsByDate2()}
                  eventKey="Activity"
                >
                  Oldest Date
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Filter by */}
            <Dropdown
              className="notification-select"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                Sort By Status
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu>
                <Dropdown.Item onClick={() => sortAll()} eventKey="Activity">
                  All
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => sortInactive()}
                  eventKey="Activity"
                >
                  Inactive
                </Dropdown.Item>

                <Dropdown.Item onClick={() => sortActive()} eventKey="Activity">
                  Active
                </Dropdown.Item>
              </Dropdown.Menu> */}

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => handlesort("all")}
                  eventKey="Activity"
                >
                  All
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => handlesort("inactive")}
                  eventKey="Activity"
                >
                  Inactive
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) => handlesort("active")}
                  eventKey="Activity"
                >
                  Active
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/*  Sort by */}
            <Dropdown
              className="notification-select"
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "1px",
              }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                {/* {apiPagination}{" "} */}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={() => setApiPagination("1-50")}
                  eventKey="Activity"
                >
                  1-50
                </Dropdown.Item>

                <Dropdown.Item
                  // onClick={() => setApiPagination("51-100")}
                  eventKey="Activity"
                >
                  51-100
                </Dropdown.Item>

                <Dropdown.Item
                  // onClick={() => setApiPagination("101-150")}
                  eventKey="Activity"
                >
                  101-150
                </Dropdown.Item>

                <Dropdown.Item
                  // onClick={() => setApiPagination("151-200")}
                  eventKey="Activity"
                >
                  151-200
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="notification-select"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                <i
                  className="fa-solid fa-location-dot mx-2"
                  style={{ color: "orange" }}
                />{" "}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={() => setfilter("1-50")}
                  eventKey="Activity"
                >
                  Thana
                </Dropdown.Item>

                <Dropdown.Item
                  //   onClick={() => setfilter("51-100")}
                  eventKey="Activity"
                >
                  Region
                </Dropdown.Item>

                <Dropdown.Item
                  // onClick={() => setfilter("101-150")}
                  eventKey="Activity"
                >
                  City
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}

            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
          </div>
        </div>
      )}{" "}
      <div className="card h-auto" style={{ marginTop: 10 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {Rider.length === 0 ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Vehicle Type</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Comment</th>
                    {/* <th className='text-center'>View Details</th> */}
                    {/* <th>Comment</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <span className='mb-0'>{item?.riderName}</span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <span className='mb-0'>
                                {item?.riderMobileNo}
                              </span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <span className='mb-0'>{item?.vehicleType}</span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <div>
                              <span className='mb-0'>
                                <td>
                                  <div>
                                    {/* <Button
                                      onClick={() => handleApproved(item._id)}
                                      className="btn btn-outline-primary"
                                    >
                                      Approve
                                    </Button> */}
                                    {item?.riderApproval === "Approved"
                                      ? "Approved"
                                      : "Pending"}
                                  </div>
                                </td>
                              </span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex gap-2'>
                            <DropdownButton
                              as={ButtonGroup}
                              title={<AiFillInteraction />}
                              id='bg-nested-dropdown'
                            >
                              <Dropdown.Item eventKey='1'>
                                {item.riderApproval === true && (
                                  <div
                                    onClick={() => {
                                      //    block(item._id);
                                    }}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Deactivate
                                  </div>
                                )}

                                {item.riderApproval === false && (
                                  <div
                                    onClick={() => {
                                      //  unBlock(item._id);
                                    }}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Activate
                                  </div>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item eventKey='2'>
                                <div
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey='3'>
                                <div
                                  onClick={() => {
                                    comment(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Comment
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey='4'>
                                <div
                                  onClick={() => {
                                    handleApproval(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Approval
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey='5'>
                                <Link
                                  to={`/riderdetails/${item?._id}`}
                                  state={item}
                                >
                                  <div
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    View
                                  </div>
                                </Link>
                              </Dropdown.Item>
                            </DropdownButton>
                            {/* {item.status === true && (
                                <button
                                  onClick={() => {
                                    block(item._id);
                                  }}
                                  style={{
                                    backgroundColor: "#db2777",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: 0,
                                    color: "white",
                                  }}
                                >
                                  Block
                                </button>
                              )}

                              {item.status === false && (
                                <button
                                  onClick={() => {
                                    unBlock(item._id);
                                  }}
                                  style={{
                                    backgroundColor: "#db2777",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: 0,
                                    color: "white",
                                  }}
                                >
                                  Unblock
                                </button>
                              )}
                              <button
                                onClick={() => {
                                  del(item._id);
                                }}
                                style={{
                                  backgroundColor: "#db2777",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  border: 0,
                                  color: "white",
                                }}
                              >
                                Delete
                              </button> */}
                          </div>
                        </td>
                        <td>{item?.comment ? item?.comment : "No Comment"}</td>

                        {/* <td>
                        <Link to={`/riderdetails/${item?._id}`} state={item}>
                          <div style={{ height: "50px", textAlign: "center" }}>
                            <AiFillEye size={40} color='orange' />
                          </div>
                        </Link>
                      </td> */}
                        {/* <td>
                          <p>This is a demo comment</p>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedRider;
