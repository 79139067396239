import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { Label } from "recharts";
import { Base_Url } from "../../../misc";
import labels from "../../../translationData/howDo.json";
import { getUserDetails } from "../../../Helper/SessionHelper";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";
import Swal from "sweetalert2";

const HowDoWeDoEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [howDoWeDoFormData, setHowDoWeDoFormData] = useState({});
  const [seoData, setSeoData] = useState({});

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedPrevBeforeImage, setSelectedPrevBeforeImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState([]);
  const [selectedPrevAfterImage, setSelectedPrevAfterImage] = useState([]);

  useEffect(() => {
    const getHowDoWeDo = (async) => {
      fetch(`${Base_Url}/get-single-how-do-we-do/${params.id}`)
        .then((res) => res.json())
        .then((data) => {
          setHowDoWeDoFormData(data.data[0]);

          if (data.data[0].beforeImage) {
            setSelectedPrevBeforeImage(data.data[0].beforeImage);
          }

          if (data.data[0].afterImage) {
            setSelectedPrevAfterImage(data.data[0].afterImage);
          }
          if (data.data[0].seo) {
            setSeoData(data.data[0].seo);
          }
        });
    };

    getHowDoWeDo();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setHowDoWeDoFormData((prev) => {
      const data = {
        ...prev,
        seo: { ...prev.seo, seoData },
        [name]: value,
      };

      return data;
    });
  };

  const allowedExtensions = ["jpg", "jpeg", "png"];

  const isFileExtensionAllowed = (fileName) => {
    const ext = fileName.split(".").pop();
    return allowedExtensions.includes(ext.toLowerCase());
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    const filteredFiles = fileList.filter(
      (file) => {
        if (!isFileExtensionAllowed(file.name)) {
          Swal.fire({
            icon: "question",
            title: "Only jpg, jpeg and png file are allowed!",
          });
        }
        return isFileExtensionAllowed(file.name);
      }
      // isFileExtensionAllowed(file.name)
    );
    setSelectedImage(filteredFiles);
  };

  const handleFileChange2 = (event) => {
    const fileList = Array.from(event.target.files);
    const filteredFiles = fileList.filter(
      (file) => {
        if (!isFileExtensionAllowed(file.name)) {
          Swal.fire({
            icon: "question",
            title: "Only jpg, jpeg and png file are allowed!",
          });
        }
        return isFileExtensionAllowed(file.name);
      }
      // isFileExtensionAllowed(file.name)
    );
    setSelectedImage2(filteredFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("folder", "HowDoWeDo/" + Seller?.userFullName);

    if (selectedImage && selectedImage?.length > 0) {
      setIsFileUploading(true);
      const beforeImagePromise = await ImageUploader(
        Seller,
        "HowDoWeDo/BeforeImage",
        selectedImage
      );

      if (beforeImagePromise && beforeImagePromise.status === "Success") {
        howDoWeDoFormData["beforeImage"] = beforeImagePromise.images;
        setIsFileUploading(false);
      }
    }

    if (selectedImage2 && selectedImage2?.length > 0) {
      setIsFileUploading(true);
      const afterImagePromise = await ImageUploader(
        Seller,
        "HowDoWeDo/AfterImage",
        selectedImage2
      );

      if (afterImagePromise && afterImagePromise.status === "Success") {
        howDoWeDoFormData["afterImage"] = afterImagePromise.images;
        setIsFileUploading(false);
      }
    }
    howDoWeDoFormData["seo"] = seoData;
    howDoWeDoFormData["userID"] = getUserDetails()._id;
    howDoWeDoFormData["sellerID"] = getUserDetails()._id;

    if (!isFileUploading) {
      console.log("howDoWeDoFormData before submit", howDoWeDoFormData);

      await axios
        .post(`${Base_Url}/update-how-do-we-do/${params.id}`, howDoWeDoFormData)
        .then((res) => {
          console.log("howDoWeDoFormData", howDoWeDoFormData);
          if (res.status === 200) {
            toast.success(
              "You have successfully update your How do we do post.",
              {
                position: "bottom-center",
                autoClose: 5000,
              }
            );
            navigate("/HowdowedoList");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("❌ Error Notify !", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <>
      <div className="container  ">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit How do we do</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit}>
                  <div className="setting-input mb-3">
                    <label className="form-label">Title *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter How Do We Do Title"
                      name="title"
                      value={
                        (howDoWeDoFormData && howDoWeDoFormData.title) || ""
                      }
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="row d-flex">
                    <label>Description</label>
                    <div className="form-group mb-3">
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        data={
                          (howDoWeDoFormData &&
                            howDoWeDoFormData.description) ||
                          ""
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setHowDoWeDoFormData((prev) => {
                            return { ...prev, description: data };
                          });
                        }}
                        style={{ height: 20 }}
                      />
                    </div>
                  </div>
                  <div className="setting-input mb-3">
                    <label className="form-label">Video URL</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Video URL"
                      name="videoURL"
                      value={
                        (howDoWeDoFormData && howDoWeDoFormData.videoURL) || ""
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* New Design */}
                  <div className="row d-flex before">
                    <div className="col-6">
                      <label>
                        {labels.beforeImg.en}
                        {"*"}
                      </label>

                      {!selectedImage ||
                        (selectedImage.length === 0 &&
                          selectedPrevBeforeImage &&
                          selectedPrevBeforeImage.length > 0 && (
                            <>
                              <div>
                                {console.log(
                                  "before img",
                                  selectedPrevBeforeImage
                                )}
                                {selectedPrevBeforeImage.map(
                                  (imgData, index) => {
                                    // console.log('img display',item);
                                    return (
                                      <img
                                        width={"150px"}
                                        height={"150px"}
                                        src={
                                          imgData.large &&
                                          imgData.large.length > 0 &&
                                          imgData.large.imageUrl // Condition 1
                                            ? imgData.large.imageUrl // If Condition 1 is true
                                            : imgData.orginalImageUrl
                                            ? imgData.orginalImageUrl
                                            : null // If Condition 1 is false, check Condition 2 and fallback to null if not defined
                                        }
                                      />
                                    );
                                  }
                                )}

                                <span
                                  style={{
                                    position: "relative",
                                    top: "-35px",
                                    left: "-26px",
                                  }}
                                >
                                  <Link
                                    to={"#"}
                                    onClick={() =>
                                      setSelectedPrevBeforeImage([])
                                    }
                                    className="btn btn-danger shadow btn-xs sharp"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </span>
                              </div>
                            </>
                          ))}

                      {(!selectedImage || selectedImage.length === 0) &&
                        (!selectedPrevBeforeImage ||
                          selectedPrevBeforeImage.length === 0) && (
                          <>
                            {" "}
                            {console.log(selectedImage)}
                            <input
                              name="beforeImage"
                              type="file"
                              id="input-file-upload1"
                              onChange={handleFileChange}
                            />
                            <label
                              id="label-file-upload1"
                              htmlFor="input-file-upload1"
                            >
                              <div>
                                <p>Drag and drop your image here</p>
                                <span className="upload-button">
                                  Upload a Image
                                </span>
                              </div>
                            </label>
                          </>
                        )}

                      {selectedImage && selectedImage.length > 0 && (
                        <>
                          <div>
                            {console.log(selectedImage)}
                            {selectedImage.map((item, index) => {
                              console.log(item);
                              return (
                                <img
                                  width={"150px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(item)}
                                />
                              );
                            })}

                            <span
                              style={{
                                position: "relative",
                                top: "-35px",
                                left: "-26px",
                              }}
                            >
                              <Link
                                to={"#"}
                                onClick={() => setSelectedImage(null)}
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                            </span>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-6">
                      <label>
                        {labels.afterImg.en}
                        {"*"}
                      </label>

                      {!selectedImage2 ||
                        (selectedImage2.length === 0 &&
                          selectedPrevAfterImage &&
                          selectedPrevAfterImage.length > 0 && (
                            <>
                              <div>
                                {console.log(
                                  "before img",
                                  selectedPrevAfterImage
                                )}
                                {selectedPrevAfterImage.map(
                                  (imgData, index) => {
                                    // console.log('img display',item);
                                    return (
                                      <img
                                        width={"150px"}
                                        height={"150px"}
                                        src={
                                          imgData.large &&
                                          imgData.large.length > 0 &&
                                          imgData.large.imageUrl // Condition 1
                                            ? imgData.large.imageUrl // If Condition 1 is true
                                            : imgData.orginalImageUrl
                                            ? imgData.orginalImageUrl
                                            : null // If Condition 1 is false, check Condition 2 and fallback to null if not defined
                                        }
                                      />
                                    );
                                  }
                                )}

                                <span
                                  style={{
                                    position: "relative",
                                    top: "-35px",
                                    left: "-26px",
                                  }}
                                >
                                  <Link
                                    to={"#"}
                                    onClick={() =>
                                      setSelectedPrevAfterImage([])
                                    }
                                    className="btn btn-danger shadow btn-xs sharp"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </span>
                              </div>
                            </>
                          ))}

                      {(!selectedImage2 || selectedImage2.length === 0) &&
                        (!selectedPrevAfterImage ||
                          selectedPrevAfterImage.length === 0) && (
                          <>
                            {" "}
                            {console.log(selectedImage2)}
                            <input
                              name="beforeImage"
                              type="file"
                              id="input-file-upload"
                              onChange={handleFileChange2}
                            />
                            <label
                              id="label-file-upload1"
                              htmlFor="input-file-upload"
                            >
                              <div>
                                <p>Drag and drop your image here</p>
                                <span className="upload-button">
                                  Upload a Image
                                </span>
                              </div>
                            </label>
                          </>
                        )}

                      {selectedImage2 && selectedImage2.length > 0 && (
                        <>
                          <div>
                            {selectedImage2.map((item, index) => {
                              console.log(item);
                              return (
                                <img
                                  width={"150px"}
                                  height={"150px"}
                                  src={URL.createObjectURL(item)}
                                />
                              );
                            })}

                            <span
                              style={{
                                position: "relative",
                                top: "-35px",
                                left: "-26px",
                              }}
                            >
                              <Link
                                to={"#"}
                                onClick={() => setSelectedImage2(null)}
                                className="btn btn-danger shadow btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </Link>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/*New design end  */}

                  <Button type="submit" style={{ marginTop: "10px" }}>
                    {" "}
                    Update
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* <Button
            style={{ marginLeft: 25 }}
            onClick={() => {
              submit();
            }}
          >
            {" "}
            Save
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default HowDoWeDoEdit;
