import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import {
//     useAllCountryQuery,
//     useGetCityByRegionQuery,
//     useGetRegionByCountryQuery,
// } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
import { Base_Url } from "../../../misc";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import noImage from "../../../images/no-img-avatar.png"

const kitchenTab = labels.userDashboard.editProfile.kitchenTab;
const amazingPhotosTab = labels.userDashboard.editProfile.amazingPhotosTab;
const experimentalPhotosTab =
  labels.userDashboard.editProfile.experimentalPhotosTab;
const otherFoodSkillsTab = labels.userDashboard.editProfile.otherFoodSkillsTab;
const myRestrictionTab = labels.userDashboard.editProfile.myRestrictionTab;
const bankTab = labels.userDashboard.editProfile.bankTab;
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;
const identityTab = labels.userDashboard.editProfile.identityTab;
const kitchenPhotosVideosTab =
  labels.userDashboard.editProfile.kitchenPhotosVideosTab;
const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;
const Update_Seller = () => {
  const [currentLanguage, setCurrentLanguage] = useState("bn");
  const basicInfoTabTitle = getTranslation(
    "tabTitle",
    currentLanguage,
    basicInfoTab
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCq3_f1JedG3GCgWnXgy3yuji4tjMZvitk",
  });
  const location = useLocation();
  const item = location.state;
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const countryResponse = await fetch(`${Base_Url}/get-country`);
        const countryData = await countryResponse.json();
        console.log(countryData);
        setCountry(countryData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchRegionData = async () => {
      try {
        const regionResponse = await fetch(`${Base_Url}/get-region-state`);
        const regionData = await regionResponse.json();
        console.log(regionData);
        setRegion(regionData.data);
      } catch (error) {
        // Handle error
      }
    };

    const fetchCityData = async () => {
      try {
        const cityResponse = await fetch(`${Base_Url}/get-city`);
        const cityData = await cityResponse.json();
        console.log(cityData);
        setCity(cityData.data);
      } catch (error) {
        // Handle error
      }
    };

    // Call the API functions
    fetchCountryData();
    fetchRegionData();
    fetchCityData();
  }, []);
  //console.log("country", country);

  console.log(item);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-4">Seller Profile Details</h3>
          <form>
            <div className="setting-right">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4>
                    {getTranslation(
                      "tabDetailsTitle",
                      currentLanguage,
                      kitchenTab
                    )}
                  </h4>
                </div>
                <div className="card-body">
                  {/* basic user information section start */}

                  {item?.sellerID && (
                    <div className="card">
                      <div className="card-body">
                        <h3 className="mb-4">{basicInfoTabTitle} </h3>

                        <p className="fs-18">
                          {" "}
                          {getTranslation(
                            "profilePhoto",
                            currentLanguage,
                            basicInfoTab
                          )}
                        </p>
                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative ">
                              <div className="avatar-preview d-flex">
                              {
                                item?.sellerID.userProfilePhoto && item?.sellerID.userProfilePhoto.length > 0 ? (
                                  <img
                                   id="saveImageFile"
                                         src={item?.sellerID?.userProfilePhoto[0]?.small?.imageUrl}
                                         alt="User Profile"
                                          />
                                     ) : (
                                     <img
                                          id="saveImageFile"
                                          src={noImage} // Replace this with the actual path to your noImage file
                                          alt="No Image"
                                          />
                                       )
                                      }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                {getTranslation(
                                  "userName",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control input-default "
                                placeholder={getTranslation(
                                  "userName",
                                  currentLanguage,
                                  basicInfoTab
                                )}
                                value={item?.sellerID?.userFullName}
                                name="userFullName"
                              />
                            </div>
                            <div className="setting-input">
                              <label
                                htmlFor="exampleInputtext"
                                className="form-label"
                              >
                                {getTranslation(
                                  "userEmailAddress",
                                  currentLanguage,
                                  basicInfoTab
                                )}{" "}
                              </label>
                              <input
                                disabled
                                type="email"
                                className="form-control input-default "
                                placeholder={getTranslation(
                                  "userEmailAddress",
                                  currentLanguage,
                                  basicInfoTab
                                )}
                                name="email"
                                value={item?.sellerID?.email}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="setting-input">
                              <div className="setting-input">
                                <label
                                  htmlFor="exampleInputtext"
                                  className="form-label"
                                >
                                  {getTranslation(
                                    "userMobileNo",
                                    currentLanguage,
                                    basicInfoTab
                                  )}
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input-default "
                                  placeholder="Mobile Number"
                                  value={item?.sellerID.userMobileNo}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      

                        {/* Map &  Address Section Start */}
                        <div className="mt-3">
                          <div className="form-group mb-3 row ">
                            <div className="col-12">
                             
                            </div>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  )}

                  {/* basic user information section end */}
                  {item?.kitchenImages && (
                    <div className="card ">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            kitchenPhotosVideosTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body d-flex">
                        {item?.kitchenImages && (
                          <PhotoProvider>
                            {item?.kitchenImages.map((item, index) => (
                              <div className='m-3 rounded'>
                                <PhotoView src={item?.large?.imageUrl}>
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                    }}
                                    src={item?.small?.imageUrl}
                                    alt=''
                                  />
                                </PhotoView>
                              </div>
                            ))}
                          </PhotoProvider>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group mb-3">
                    <label>
                      {getTranslation(
                        "kitchenName",
                        currentLanguage,
                        kitchenTab
                      )}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control input-default "
                      placeholder={getTranslation(
                        "kitchenName",
                        currentLanguage,
                        kitchenTab
                      )}
                      name="kitchenName"
                      value={
                        item && item?.kitchenName && (item?.kitchenName || "")
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>
                        {getTranslation(
                          "kitchenAddressHeading",
                          currentLanguage,
                          kitchenTab
                        )}{" "}
                      </h5>
                    </div>

                    {item?.countryID && (
                      <div className="col-4">
                        <label>
                          {getTranslation(
                            "country",
                            currentLanguage,
                            kitchenTab
                          )}{" "}
                        </label>
                        <select
                          name="countryAddress"
                          className="form-control input-default"
                        >
                          {country.length > 0 &&
                            country.map((i) => {
                              if (country && i?._id === item?.countryID) {
                                console.log(i, "afdasdf");
                                return (
                                  <option selected value={i?._id}>
                                    {i?.countryName}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    )}

                    {item?.regionID && (
                      <div className="col-4">
                        <label>
                          {getTranslation(
                            "region",
                            currentLanguage,
                            kitchenTab
                          )}{" "}
                        </label>
                        <select className="form-control input-default">
                          {region.length > 0 &&
                            region.map((i) => {
                              if (region && i?._id === item?.regionID) {
                                return (
                                  <option selected value={i._id}>
                                    {i.regionName}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    )}

                    {item?.cityID && (
                      <div className="col-4 mb-3">
                        <label>
                          {getTranslation("city", currentLanguage, kitchenTab)}{" "}
                        </label>
                        <select className="form-control input-default">
                          {city?.length > 0 &&
                            city?.map((i) => {
                              if (city && i?._id === item?.cityID) {
                                return (
                                  <option selected value={i._id}>
                                    {i.cityName}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    )}
                    {item?.pointLocation && (
                      <GoogleMap
                        mapContainerStyle={{ height: "200px", width: "100%" }}
                        //onDragEnd={handleDrag}
                        center={item?.pointLocation?.coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                        zoom={20}
                        // onLoad={handleMapLoad}
                      >
                        <Marker
                          position={item?.pointLocation?.coordinates}
                          draggable
                        />
                      </GoogleMap>
                    )}
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <label>
                        {getTranslation("address", currentLanguage, kitchenTab)}
                      </label>
                      <textarea
                        class="form-control"
                        value={item && item?.address && (item?.address || "")}
                        rows="5"
                      ></textarea>
                    </div>

                    <div className="col-6">
                      <label>
                        {getTranslation(
                          "policeStation",
                          currentLanguage,
                          kitchenTab
                        )}
                      </label>
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        value={
                          item &&
                          item?.policeStation &&
                          (item?.policeStation || "")
                        }
                        placeholder={getTranslation(
                          "policeStation",
                          currentLanguage,
                          kitchenTab
                        )}
                      />
                    </div>
                  </div>

                  {/* Map &  Address Section Start */}
                  {/* <div className='mt-3'>
                                <div className='form-group mb-3 row '>
                                    <div className='col-12'>
                                        <label>{getTranslation('pointLocation', currentLanguage,kitchenTab)} </label>
                                        <PlacesAutocomplete
                                            searchOptions={searchOptions}
                                            value={userAddress || ""}
                                            onChange={setUserAddress}
                                            onSelect={handleSelect}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div style={{ position: "relative" }}>
                                                    <input disabled
                                                        {...getInputProps({
                                                            placeholder: "Search Places ...",
                                                            className: "form-control input-default location-search-input",
                                                        })}
                                                    />
                                                    <div className='autocomplete-dropdown-container' style={{ position: "absolute", zIndex: 2, width: "100%", border: "1px solid #e7e7e7" }}>
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? {
                                                                    backgroundColor: "#1E466A",
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                    padding: "10px"
                                                                }
                                                                : {
                                                                    backgroundColor: "#ffffff",
                                                                    cursor: "pointer",
                                                                    padding: "10px",
                                                                };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                </div>
                                <GoogleMap
                                    mapContainerStyle={{ height: "200px", width: "100%" }}
                                    //onDragEnd={handleDrag}
                                    center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                                    zoom={20}
                                    onLoad={handleMapLoad}
                                >
                                    <Marker
                                        position={coordinates}
                                        draggable
                                        onDragEnd={handleDrag}
                                    />
                                </GoogleMap>
                            </div> */}

                  <div className="row mt-4">
                    <div className="form-group mb-3 col-6">
                      <label>
                        {getTranslation(
                          "nationality",
                          currentLanguage,
                          kitchenTab
                        )}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        // name='myCountry'
                        className="form-control input-default  "
                      >
                        {country?.length > 0 &&
                          country.map((i) => {
                            if (country && i._id === item?.sellerNationality) {
                              return (
                                <option selected value={i._id}>
                                  {i?.countryName}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>
                    <div className="form-group mb-3 col-6">
                      <label>
                        {getTranslation(
                          "decentNationality",
                          currentLanguage,
                          kitchenTab
                        )}
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        // name='decentCountry'
                        className="form-control input-default "
                      >
                        {country?.length > 0 &&
                          country.map((i) => {
                            if (
                              country &&
                              i._id === item?.sellerNationalityByBornID
                            ) {
                              console.log(i, "afdasdf");
                              return (
                                <option selected value={i._id}>
                                  {i.countryName}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>
                  </div>

                  {/* amazing dish Section start */}
                  {item?.dishesMedia && (
                    <div className="setting-right">
                      <div className="card">
                        <div className="card-header border-0 pb-0">
                          <h3>
                            {getTranslation(
                              "tabDetailsTitle",
                              currentLanguage,
                              amazingPhotosTab
                            )}
                          </h3>
                        </div>
                        <div className="d-flex">
                          {item?.dishesMedia[0] &&
                            item?.dishesMedia[0]?.photo && (
                              <PhotoProvider>
                                {item?.dishesMedia[0]?.photo?.map(
                                  (item, index) => (
                                    <div className='m-3 rounded'>
                                      <PhotoView src={item?.large?.imageUrl}>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl}
                                          alt=''
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* amazing dish Section end */}

                  {/* experimental food  Section end */}

                  {item?.myExperimentalFoodMedia && (
                    <div className="setting-right">
                      <div className="card">
                        <div className="card-header border-0 pb-0">
                          <h3>
                            {getTranslation(
                              "tabDetailsTitle",
                              currentLanguage,
                              experimentalPhotosTab
                            )}
                          </h3>
                        </div>
                        <div className="d-flex">
                          {item?.myExperimentalFoodMedia[0] &&
                            item?.myExperimentalFoodMedia[0]?.photo && (
                              <PhotoProvider>
                                {item?.myExperimentalFoodMedia[0]?.photo.map(
                                  (item, index) => (
                                    <div className='m-3 rounded'>
                                      <PhotoView src={item?.large?.imageUrl}>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl}
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* experimental food Section end */}

                  {/* others food skill Section start */}
                  {item?.otherFoodSkillsMedia && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            otherFoodSkillsTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-content-end">
                          <div className="col-12">
                            {item?.otherFoodSkillsMedia && (
                              <PhotoProvider>
                                {item?.otherFoodSkillsMedia[0]?.photo?.map(
                                  (item, index) => (
                                    <div className='m-3 rounded'>
                                      <PhotoView src={item?.large?.imageUrl}>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl}
                                          alt=''
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* others food skill Section end */}

                  {/* my MyRestrictions Section start */}

                  {item?.myRestrictions && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            myRestrictionTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <ul className="checkbox-group">
                              {item?.myRestrictions?.map((item, index) => (
                                <li>
                                  <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex">
                                    <input
                                      disabled
                                      type="checkbox"
                                      value={item}
                                      className="form-check-input"
                                      id={`customCheckBox_${index}`}
                                      checked
                                      // checked={Tag && Tag.includes(item.tagName)}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor={`customCheckBox_${index}`}
                                    >
                                      {item}
                                    </label>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* my MyRestrictions Section end */}

                  {/* bank details section start */}
                  {item?.bankDetails && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h3>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            bankTab
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "accountName",
                                currentLanguage,
                                bankTab
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              disabled
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "accountName",
                                currentLanguage,
                                bankTab
                              )}
                              name='bankAccName'
                              value={item?.bankDetails[0]?.bankAccName}
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "accountNo",
                                currentLanguage,
                                bankTab
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              disabled
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "accountNo",
                                currentLanguage,
                                bankTab
                              )}
                              name='bankAccNo'
                              value={item?.bankDetails[0]?.bankAccNo}
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "bankName",
                                currentLanguage,
                                bankTab
                              )}
                            </h6>
                          </div>
                          <div className='col-xl-8 mb-3'>
                            <select className='form-control'>
                              <option>{item?.bankDetails[0]?.bankName}</option>
                            </select>
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "branchName",
                                currentLanguage,
                                bankTab
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              disabled
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "branchName",
                                currentLanguage,
                                bankTab
                              )}
                              name='bankBranchName'
                              value={item?.bankDetails[0]?.bankBranchName}
                            />
                          </div>
                          <div className="col-xl-4 mb-3">
                            <h6 className="mb-0">
                              {getTranslation(
                                "routingNo",
                                currentLanguage,
                                bankTab
                              )}
                            </h6>
                          </div>
                          <div className="col-xl-8 mb-3">
                            <input
                              disabled
                              type="text"
                              className="form-control input-default "
                              placeholder={getTranslation(
                                "routingNo",
                                currentLanguage,
                                bankTab
                              )}
                              name='bankRoutingNo'
                              value={item?.bankDetails[0]?.bankRoutingNo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* bank details section end */}

                  {/* mobile bank section start */}
                  {item?.mobileBanking && (
                    <div className="card">
                      <div className="card-body">
                        <div className="card-header border-0 pb-0">
                          <h3>
                            {getTranslation(
                              "tabDetailsTitle",
                              currentLanguage,
                              mobileBankingTab
                            )}
                          </h3>
                        </div>
                        <div className="card-body">
                          {item?.mobileBanking &&
                            item?.mobileBanking.length > 0 && (
                              <>
                                <div className="row">
                                  <div className="col-5">
                                    <label>
                                      {getTranslation(
                                        "selectProvider",
                                        currentLanguage,
                                        mobileBankingTab
                                      )}
                                    </label>
                                  </div>
                                  <div className="col-5">
                                    <label>
                                      {getTranslation(
                                        "selectMobileNo",
                                        currentLanguage,
                                        mobileBankingTab
                                      )}
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  {item?.mobileBanking.map((item) => (
                                    <div className="row mt-2">
                                      <div className="form-group col-5">
                                        <input
                                          disabled
                                          type="text"
                                          className="form-control"
                                          placeholder={getTranslation(
                                            "selectMobileNo",
                                            currentLanguage,
                                            mobileBankingTab
                                          )}
                                          value={item?.operatorName || ""}
                                        />
                                      </div>
                                      <div className="form-group col-5">
                                        <input
                                          disabled
                                          type="text"
                                          className="form-control"
                                          placeholder={getTranslation(
                                            "selectMobileNo",
                                            currentLanguage,
                                            mobileBankingTab
                                          )}
                                          value={item?.mobileNumber || ""}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* mobile bank section end */}

                  {/* identity section start */}

                  {item?.sellerID?.Identity && (
                    <div className="card">
                      <div className="card-header border-0 pb-0">
                        <h4>
                          {getTranslation(
                            "tabDetailsTitle",
                            currentLanguage,
                            identityTab
                          )}
                        </h4>
                      </div>
                      <div className="card-body">
                        <div>
                          {/* <hr className="my-5" /> */}
                          {item?.sellerID?.Identity &&
                            item?.sellerID?.Identity[0]?.type && (
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <div className="form-group mb-3">
                                    <label>
                                      {getTranslation(
                                        "selectIdType",
                                        currentLanguage,
                                        identityTab
                                      )}{" "}
                                    </label>
                                    <select
                                      name="countryAddress"
                                      className="form-control input-default"
                                    >
                                      <option selected>
                                        {item?.sellerID?.Identity[0]?.type}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          {item?.sellerID?.Identity &&
                            item?.sellerID?.Identity[0]?.Number && (
                              <div className="form-group mb-3">
                                <label>
                                  {getTranslation(
                                    "idNumber",
                                    currentLanguage,
                                    identityTab
                                  )}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input-default "
                                  placeholder={getTranslation(
                                    "idNumberPlaceholder",
                                    currentLanguage,
                                    identityTab
                                  )}
                                  value={item?.sellerID?.Identity[0]?.Number}
                                />
                              </div>
                            )}

                          {item?.sellerID?.Identity &&
                            item?.sellerID?.Identity[0]?.Img[0] && (
                              <PhotoProvider>
                                {item?.sellerID?.Identity[0]?.Img.map(
                                  (item, index) => (
                                    <div className="m-3 rounded">
                                      <PhotoView src={item?.large?.imageUrl}>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                          }}
                                          src={item?.small?.imageUrl}
                                          alt=""
                                        />
                                      </PhotoView>
                                    </div>
                                  )
                                )}
                              </PhotoProvider>
                            )}
                        </div>

                        {/* <div className="col-12 mt-3">
                          <div className="setting-input">
                            <label
                              htmlFor="exampleInputtext"
                              className="form-label"
                            >
                              {getTranslation(
                                "idDateOfBirth",
                                currentLanguage,
                                identityTab
                              )}
                            </label>
                            <input disabled
                              type="text"
                              className="form-control input-default "
                              placeholder="Date of Birth"
                              {...register("userDateOfBirth", {
                                required: true,
                                onChange: handleInputChange,
                              })}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}

                  {/* identity section end */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Update_Seller;
